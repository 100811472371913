import React from 'react';
// import sections
import Cta from '../components/sections/Cta';

const Investors = () => {

  return (
    <>
      <Cta split />
    </>
  );
}

export default Investors;