import React from 'react';
// import sections
import FeaturesTiles from '../components/sections/FeaturesTiles';

const About = () => {

  return (
    <>
      <FeaturesTiles />
    </>
  );
}

export default About;